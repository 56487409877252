import NavBar from "./nav";
import { useState,useEffect} from "react";
import axios from 'axios'
import { Link } from "react-router-dom";
import {getCookie} from '../helper/helper'


const data = {
    isloading : false,
    a : 0,
    b : 0,
    c : 0,
    d : 0
}

const Dashboard = () => {
     
    const [value,setValue] = useState(data);
    let token = getCookie('token');

    const contactTo = "https://khudra.asia/api/count/contactTo";
    const venturesRequest = "https://khudra.asia/api/count/venturesRequest";
    const communityRequest = "https://khudra.asia/api/count/communityRequest";
    const careersRequest = "https://khudra.asia/api/count/careersRequest";
    
     let headers = {headers : {
        Authorization : "Bearer " + token
      }}

      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;


    

useEffect(() => {
    const conctactCount = axios.get(contactTo,headers);
    const venturesCount = axios.get(venturesRequest,headers);
    const communityCount = axios.get(communityRequest,headers);
    const CarrersCount = axios.get(careersRequest,headers);

    axios.all([conctactCount,venturesCount,communityCount,CarrersCount]).then(
        axios.spread((...allData) => {
         
            b = allData[1].data.count;
            c = allData[2].data.count
            d = allData[3].data.count
            setValue({...value,a:allData[0].data.count,b,c,d})
        })
    )  
},[value])

   

    
    
   
        
    
  






    return (
        <>
            <NavBar />
            <div className="columns m-6">
                <div className="column ">
                <Link to='/contactsData'>
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                            <center><h5 className="title is-6" style={{ 'color': "grey" }}>CONTACTS REQUEST COUNT</h5></center>
                            <center><h1 className="title is-1" style={{ 'color': "grey" }}>{value.a}</h1></center>
                            </div>
                        </div>
                    </div>
                </Link>
                </div>
                <div className="column">
                <Link to='/venturesData'>
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                            <center><h5 className="title is-6" style={{ 'color': "grey" }}>VENTURES REQUEST COUNT</h5></center>
                            <center><h1 className="title is-1" style={{ 'color': "grey" }}>{value.b}</h1></center>
                            </div>
                        </div>
                    </div>
                </Link>
                </div>
                <div className="column">
                <Link to='/communityData'>
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                            <center><h6 className="title is-6" style={{ 'color': "grey" }}>COMMUNITY REQUEST COUNT</h6></center>
                            <center><h1 className="title is-1" style={{ 'color': "grey" }}>{value.c}</h1></center>
                            </div>
                        </div>
                    </div>
                </Link>
                </div>
                <div className="column">
                <Link to='/carrersData'>
                    <div className="card">
                        <div className="card-content">
                            <div className="content">
                            <center><h5 className="title is-6" style={{ 'color': "grey" }}> CARRERS REQUEST <br/> COUNT</h5></center>
                            <center><h1 className="title is-1" style={{ 'color': "grey" }}>{value.d}</h1></center>
                            </div>
                        </div>
                    </div>
                </Link>
                </div>
            </div>
        </>
    )
}

export default Dashboard;