
import { Link } from 'react-router-dom'
import kimg from '../images/khudra-logo.png'
import { signOut } from '../helper/helper'

const NavBar = () => {

  const onclick = (e) => {
    e.preventDefault();
    signOut();
    window.location.reload(false);
  }

  return (
    <nav className="navbar" style={{ 'boxShadow': "0 2px 2px -2px rgba(0,0,0,.2)", 'height': "75px" }} role="navigation" aria-label="main navigation">
      <div className="navbar-brand ml-5 mr-5">

        <Link to='/' className="navbar-item">
          <img src={kimg} style={{ "Width": "120px" }}></img>
        </Link>


        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
          <Link to='/' className="navbar-item">
            Home
          </Link>
          <Link to='/contactsData' className="navbar-item">
            Contacts Data
          </Link>
          <Link to='/venturesData' className="navbar-item">
            Ventures Data
          </Link>
          <Link to='/communityData' className="navbar-item">
            Community Request Data
          </Link>
          <Link to='/carrersData' className="navbar-item">
            Carrers Request Data
          </Link>
     
      <div class="navbar-item navbar-end">
        <div class="buttons">
          <a class="button is-primary" onClick={onclick}>
            <strong>Logout</strong>
          </a>
        </div>
      
    </div>
      
        </div>
      </div>
    </nav>
  )
}


export default NavBar;