
import { Link } from 'react-router-dom'
import kimg from '../images/khudra-logo.png'


const NavHome= () => {



  return (
    <nav className="navbar" style={{ 'boxShadow': "0 2px 2px -2px rgba(0,0,0,.2)", 'height': "75px" }} role="navigation" aria-label="main navigation">
      <div className="navbar-brand ml-5 mr-5">

        <Link to='/' className="navbar-item">
          <img src={kimg} style={{ "Width": "120px" }}></img>
        </Link>
      
       </div>
    </nav>
  )
}


export default NavHome;