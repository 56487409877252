
import limg from '../images/login.jpg'
import { useState} from 'react'
import {Redirect} from 'react-router-dom'
import MessageDisplay from '../helper/messageDisplay'
import axios from 'axios'
import styled, { keyframes } from 'styled-components';
import { fadeInLeft, fadeInRight } from 'react-animations';
import { authenticate, isAuth } from '../helper/helper';
import Homenav from './Homenav'

const Animation = keyframes`${fadeInLeft}`;

const LoginAnimation = styled.div`
  animation: 0.9s ${Animation};
  animation-timing-function: ease-out;
`;

const Animations = keyframes`${fadeInRight}`;

const ImageAnimation = styled.div`
  animation: 0.9s ${Animations};
  animation-timing-function: ease-out;
`;

let initialValue = {
    email: '',
    password: '',
    message: '',
    type: '',
    loading: false,
    error: false
}


const Login = ({history}) => {
    const [values, setValues] = useState(initialValue);
 
    const {
        username,
        type,
        password,
        message,
        error,
        loading,
    } = values
    
    const onchange = name => event => {
        setValues({ ...values, [name]: event.target.value })
    }

    const onsubmit = (e) => {
        e.preventDefault();
        setValues({ ...values,loading: true })
        axios({
            method: 'POST',
            url: `https://khudra.asia/api/login`,
            data: {username, password }
        })
        .then(response => {
            authenticate(response, () => {
                setValues({
                    ...values,
                    error: false,
                    email: '',
                    password: '',
                    type: 'is-primary',
                    message: response.data.message,
                    loading: false,
                })
            })
             isAuth() ? history.push('/'): <Redirect to = '/'/>
             window.location.reload();
        })
        .catch(error => {
            console.log(error);
            setValues({
                ...values,
                error: true,
                type: 'is-danger',
                message: "Please check username and password and try again",
                loading: false,
            })
        })
    }
   

    return (
<>
    <Homenav/>
        <div className="columns mt-6">
        
            <div className="column is-two-fifths ">
            <LoginAnimation>
                <div className="container m-6">
                    <h5 className="title is-6" style={{ 'color': "grey" }}>LOGIN</h5>
                    <p className="subtitle is-3 mb-2" style={{ 'fontWeight': '500' }}>Welcome back</p>
                    <p className="subtitle is-6 " style={{ 'color': "grey" }}>Login to manage your admin panel.</p>
                </div>
            </LoginAnimation>
            <LoginAnimation>
            <form onSubmit={onsubmit}>
                <div className="m-6">
            
         
                    {error ? <MessageDisplay message={message} type={type} /> : null}
    
                    <div class="field">
                        <p className="subtitle is-6 mb-3" style={{ 'color': "grey", "fontSize": "14px" }}>Enter your username</p>
                        <input className="input" onChange={onchange('username')} value={username} type="text" placeholder="username" style={{ 'height': "50px" }} required/>
                    </div>
                    <div className="field mt-5">
                        <p className="subtitle is-6 mb-3" style={{ 'color': "grey", "fontSize": "14px", "float": "left" }} required>Enter your password</p>
                        <input className="input" type="password" onChange={onchange('password')} value={password} placeholder="Password" style={{ 'height': "50px" }} />
                    </div>
                    <button className="button is-primary mt-5" type="submit" disabled={loading}>Login</button>
                </div>
             </form>
             </LoginAnimation>
            </div>
       
    <ImageAnimation>
            <div className="column mt-6">
                <img src={limg} alt="desc"/>
            </div>
    </ImageAnimation>
        </div>
</>
    )
}

export default Login;