import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bulma/css/bulma.min.css';
import './Component/fontawesome/css/all.css';

import Routes from './Routes';

ReactDOM.render(
    <Routes />,
  document.getElementById('root')
);


