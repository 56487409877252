

const Modal = ({name,email,message,id}) => {
    
    

    return (
        <div className="modal" id={id}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">Message</p>
                    <button className="delete" aria-label="close" onClick={() => {
                             let a = document.getElementById(id);
                             a.classList.remove('is-active')
                    }}></button>
                </header>

            
                <section className="modal-card-body">

                <div class="field">
                    <label class="label">Name</label>
                    <div class="control has-icons-left has-icons-right">
                        <input class="input is-success" type="text" placeholder="Text input" value="bulma" disabled value={name}/>
                            <span class="icon is-small is-left">
                                <i class="fas fa-user"></i>
                            </span>
                    </div>
                </div>


                <div class="field">
                    <label class="label">Email</label>
                    <div class="control has-icons-left has-icons-right">
                        <input class="input is-success" type="text" placeholder="Text input" value="bulma" disabled value={email}/>
                            <span class="icon is-small is-left">
                                <i class="fas fa-envelope"></i>
                            </span>
                    </div>
                </div>




                    <div className="field">
                        <div className="control">
                            <textarea className="textarea" placeholder="Textarea" disabled>
                               {message}
                            </textarea>
                        </div>
                    </div>

                </section>
            </div>
        </div>
    )
}

export default Modal;