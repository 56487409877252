
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import Carrers from './Component/carrers';
import Community from './Component/community';
import Contacts from './Component/contacts';
import Dashboard from './Component/Dashboard';
import Login from './Component/Login'
import Ventures from './Component/ventures';
import PublicRoute from './helper/PublicRoute'
import PrivateRoute from './helper/PrivateRoute';


const onclick = (id) => {
    let idd = id + "@123"
    let a = document.getElementById('idd');
   console.log(a);
}


const Routes = () => {
    return (
        <Router>
            <Switch>
                <PrivateRoute exact path = "/dashboard" component={Dashboard}/>
                <PrivateRoute exact path = "/contactsData" component={Contacts}/>
                <PublicRoute restricted={true} exact path = "/" component={Login}/>
                <PrivateRoute exact path = "/venturesData" component={Ventures}/>
                <PrivateRoute exact path = "/communityData" component={Community}/>
                <PrivateRoute exact path = "/carrersData" component={Carrers}/>
            </Switch>
        </Router>
    )
}

export default Routes;