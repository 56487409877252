import NavBar from "./nav";
import axios from "axios";
import { useState, useEffect } from 'react'
import {getCookie} from '../helper/helper'
import Modal from './Modal'



const Carrers = () => {
    const [Values, setValue] = useState([]);
    const [loading,isLoading] = useState(false);
    let count = 0;
    let token = getCookie('token');

    useEffect(() => {
        isLoading(true);
        axios.get('https://khudra.asia/api/careersRequest',{headers : {
            Authorization : "Bearer " + token
        }})
            .then(response => {
                setValue(...Values, response.data);
                isLoading(false);
            })
            .catch(error => {
                console.log(error)
            })
    }, [])
   


     console.log(Values);



    return (
        <>
            <NavBar />
            {!loading ? <>
            <div className="container m-6">
                    <h5 className="title is-6" style={{ 'color': "grey" }}>Khudra panel</h5>
                    <p className="subtitle is-3 mb-2" style={{ 'fontWeight': '500' }}>Carrers Request</p>
                </div>
            <table className="table m-6">
                <thead>
                    <tr>
                        <th>S.N</th>
                        <th>Carrers</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Attachments</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Values.map((ele, key) => {

                            count++;
                            let location = "";
                            let date = new String(ele.updatedAt);
                            date = date.slice(0,10);
                            return (

                                <tr key={key}>
                                    <td>{count}</td>
                                    <td><div className="columns is-multiline">{ele.careers.map((ele) => {
                                        return (
                                            
                                                <div className="column"  style={{"max-width":"130px"}} key={key}>
                                                    <article className="message is-primary" style={{"max-width":"150px"}}>
                                                        <div className="message-header">
                                                            <p style={{ "fontSize": "12px" }} >{ele}</p>
                                                        </div>
                                                    </article>
                                                </div>
                                        )
                                    })}
                                  </div>
                                    </td>
                                    <td>{ele.name}</td>
                                    <td>{ele.email}</td>
                                    <td>
                                     
                                     <button class="button is-info is-small" onClick={() => {
     
                                          let a = document.getElementById(ele._id);
                                          a.classList.add('is-active')
                                     
                                     }}>View Message</button>
                                     <Modal name={ele.name} email={ele.email} message={ele.mess} id={ele._id}/>
                                


                                  </td>
                            
                                    <td>
                                        {
                                             ele.attachments.length ? 
                                             ele.attachments.map(a => {
                                                 location = a.location;
                                                 location = "https://khudra.asia/" + location.match(/careersRequest.*/)[0];
                        
                                                
                                                 return (
                                                    <a href={location} target="_blank"><button class="button is-info is-small" style={{"margin":"0.5%"}}>{a.name.slice(0,30)}</button></a>
                                                 )
                                             }
                                             
                                            ) : <a href={location} target="_blank"><button class="button is-info is-small" disabled={true}>View attachments</button></a>
                                        }
                                       
                                    </td>
                                    <td>{date}</td>
                                </tr>
                            )

                        })
                    }
                </tbody>
            </table>
            </> : <div className="container m-6">
                    <h5 className="title is-6" style={{ 'color': "grey" }}>Loading..</h5>
                </div>}
    
        </>
                
    )
}

export default Carrers;